import { ReactNode } from 'react';

import PrivacyPolicySVG from '@/icons/PrivacyPolicySVG';
import type { WindowSizes } from '@/types/common.types';

import { ActorRolesEnum } from '@/enums/actor-roles.enums';
import { BannerLinkEnum } from '@/enums/banner-link.enums';
import { ModuleTypesEnum } from '@/enums/module-types.enums';
import { SubscriptionDomain } from '@/enums/profile-subscriptions-paths.enums';
import { QualityEnum } from '@/enums/quality.enums';
import { ProfileDomain } from '@/enums/user-profile-paths.enums';

export type ErrorType = Array<{
  title: string;
  message: string;
  id: number;
  placeholder: string;
}>;

// =================================================================

export const modules = [
  {
    id: '3',
    title: 'header.navigation.movies',
    moduleType: 'movies',
    isVisible: true,
  },
  {
    id: '4',
    title: 'header.navigation.serials',
    moduleType: 'movies',
    isVisible: true,
  },
  {
    id: '1',
    title: 'header.navigation.onlineTV',
    moduleType: 'online-tv',
    isVisible: true,
  },
  {
    id: '5',
    title: 'header.navigation.cartoons',
    moduleType: 'movies',
    isVisible: true,
  },
  {
    id: '6',
    title: 'header.navigation.uzVideo',
    moduleType: 'movies',
    isVisible: true,
  },
  {
    id: '12',
    title: 'header.profile.subscriptions',
    moduleType: 'subscriptions',
    isVisible: true,
  },
  {
    id: '7',
    title: 'header.navigation.anime',
    moduleType: 'movies',
    isVisible: false,
  },
  {
    id: '11',
    title: 'header.navigation.collections',
    moduleType: 'collections',
    isVisible: false,
  },
] as const;

export const privacyPolicyPath: {
  title: string;
  href: string;
  icon: ReactNode;
  isTasixOnly: boolean;
} = {
  title: 'header.navigation.privacyPolicy',
  href: '/privacy-policy',
  icon: <PrivacyPolicySVG />,
  isTasixOnly: false,
};

// =================================================================

export const moviesModulesIds = ['3', '4', '5', '6', '7'];

// =================================================================

export const moduleTypes: Record<ModuleTypesEnum, string> = {
  movies: 'movies',
  onlineTv: 'online-tv',
  collection: 'collection',
  collections: 'collections',
} as const;

// =================================================================

export const breakpoints: Record<WindowSizes, string> = {
  extraLarge: '(min-width: 1200px)',
  large: '(min-width: 992px)',
  medium: '(min-width: 768px)',
  small: '(min-width: 576px)',
  extraSmall: '(min-width: 0px)',
} as const;

// =================================================================

export const qualityTitles: Record<QualityEnum, string> = {
  sd: 'sd',
  hd: 'hd',
  full_hd: 'full hd',
  three_d: '3d',
  four_k: '4k',
} as const;

// =================================================================

export const roleTitles: Record<ActorRolesEnum, string> = {
  actor: 'Актёр',
  director: 'Режиссёр',
  scenarist: 'Сценарист',
  producer: 'Продюсер',
} as const;

// =================================================================

export const bannerLinkTypes: Record<BannerLinkEnum, number> = {
  externalLink: 0,
  movie: 1,
  collection: 2,
} as const;

// =================================================================

export const errorTypes: ErrorType = [
  {
    title: 'Плохое изображение',
    message: 'bad_picture',
    id: 0,
    placeholder: 'пожалуйста, подробно опишите проблему с содержимым файла',
  },
  {
    title: 'Плохой звук',
    message: 'bad_sound',
    id: 1,
    placeholder: 'пожалуйста, подробно опишите проблему с содержимым файла',
  },
  {
    title: 'Не работает',
    message: 'not_working',
    id: 2,
    placeholder: 'пожалуйста, подробно опишите проблему с содержимым файла',
  },
  {
    title: 'Другое',
    message: 'other',
    id: 3,
    placeholder: 'пожалуйста, подробно опишите проблему с содержимым файла',
  },
] as const;

// =================================================================

export const profileDomains: Array<{
  title: string;
  domain: ProfileDomain;
  isTasixOnly: boolean;
}> = [
  {
    title: 'header.profile.account',
    domain: ProfileDomain.Account,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.subscriptions',
    domain: ProfileDomain.Subscriptions,
    isTasixOnly: true,
  },
  {
    title: 'header.profile.favorites',
    domain: ProfileDomain.Favorites,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.history',
    domain: ProfileDomain.History,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.sessions',
    domain: ProfileDomain.Sessions,
    isTasixOnly: false,
  },
  {
    title: 'header.profile.paymentMethod',
    domain: ProfileDomain.Payments,
    isTasixOnly: true,
  },
];

export const subscriptionDomains: Array<{
  title: string;
  domain: SubscriptionDomain;
}> = [
  {
    title: 'header.subscriptions.purchasing',
    domain: SubscriptionDomain.Purchasing,
  },
  {
    title: 'header.subscriptions.active',
    domain: SubscriptionDomain.Active,
  },
  {
    title: 'header.subscriptions.autoRenewal',
    domain: SubscriptionDomain.AutoRenewal,
  },
  {
    title: 'header.subscriptions.history',
    domain: SubscriptionDomain.History,
  },
  {
    title: 'header.subscriptions.promoCode',
    domain: SubscriptionDomain.promoCode,
  },
];
