import type { Locale } from '@/types/common.types';

import { QueryFilterParams } from '@/api/types/filters.types';

export const HomeQueryKeys = {
  PREFIX: 'home',

  unfinshedMovies: (locale: Locale) => [HomeQueryKeys.PREFIX, 'unfinished-movies', locale],
  homeGenres: (locale: Locale) => [HomeQueryKeys.PREFIX, 'genres', locale],
  collections: (locale: Locale) => [HomeQueryKeys.PREFIX, 'collections', locale],
} as const;

// =================================================================

export const ProfileQueryKeys = {
  PREFIX: 'profile',

  activeSubscriptions: (locale: Locale) => [
    ProfileQueryKeys.PREFIX,
    'active-subscriptions',
    locale,
  ],
  autoRenewalSubscriptions: (locale: Locale, page: number = 1) => [
    ProfileQueryKeys.PREFIX,
    'auto-renewal-subscriptions',
    locale,
    page,
  ],
  historySubscription: () => [ProfileQueryKeys.PREFIX, 'history-subscription'],
  favoritesMovies: (locale: Locale) => [ProfileQueryKeys.PREFIX, 'favorites-movie', locale],
  historyMovies: (locale: Locale) => [ProfileQueryKeys.PREFIX, 'history-movie', locale],
  subscriptionCards: (moduleId: string, locale?: Locale) => [
    ProfileQueryKeys.PREFIX,
    'subscription-cards',
    moduleId,
    locale,
  ],
  subscription: (moduleId?: string, locale?: Locale) => [
    ProfileQueryKeys.PREFIX,
    'subscription',
    moduleId || 'all',
    locale,
  ],
  sessionsList: (locale: Locale) => [ProfileQueryKeys.PREFIX, 'sessions-list', locale],
} as const;

// =================================================================

export const MovieQueryKeys = {
  PREFIX: 'movie',

  preferedFilterList: () => [MovieQueryKeys.PREFIX, 'prefered-filters-list'],
  movies: (moduleId: string, filtersQuery: QueryFilterParams, locale: Locale) => [
    MovieQueryKeys.PREFIX,
    'list',
    moduleId,
    filtersQuery,
    locale,
  ],
  moviesByGenre: (genreId: string | number, locale: Locale) => [
    MovieQueryKeys.PREFIX,
    'genre-list',
    genreId.toString(),
    locale,
  ],
  moviesByCountry: (countryId: string | number, locale: Locale) => [
    MovieQueryKeys.PREFIX,
    'country-list',
    countryId.toString(),
    locale,
  ],
  moviesByYear: (yearId: string | number, locale: Locale) => [
    MovieQueryKeys.PREFIX,
    'year-list',
    yearId.toString(),
    locale,
  ],
  moviesByPerson: (personId: string | number, roleId: string | number | null, locale: Locale) => [
    MovieQueryKeys.PREFIX,
    'person-list',
    personId.toString(),
    roleId?.toString(),
    locale,
  ],
  movieFiles: (moduleId: string, movieId: string) => [
    MovieQueryKeys.PREFIX,
    'files',
    moduleId,
    movieId,
  ],
  serialEpisodes: (moduleId: string, movieId: string, locale: Locale) => [
    MovieQueryKeys.PREFIX,
    'serial-episodes',
    moduleId,
    movieId,
    locale,
  ],
  serialFiles: (params: {
    moduleId: string;
    movieId: string;
    seasonId: string;
    page: number;
    locale: Locale;
  }) => {
    const { moduleId, movieId, seasonId, page, locale } = params;

    return [MovieQueryKeys.PREFIX, 'serial-files', seasonId, moduleId, movieId, page, locale];
  },
  moviePersonRoles: (personId: string) => [MovieQueryKeys.PREFIX, 'person-roles', personId],
  movieSnapshots: (moduleId: string, movieId: string) => [
    MovieQueryKeys.PREFIX,
    'movie-snapshots',
    moduleId,
    movieId,
  ],
  relatedMovies: (moduleId: string, movieId: string, locale: Locale) => [
    MovieQueryKeys.PREFIX,
    'related-movies',
    moduleId,
    movieId,
    locale,
  ],
};

// =================================================================

export const CollectionsQueryKeys = {
  PREFIX: 'collections',

  list: (locale: Locale) => [CollectionsQueryKeys.PREFIX, 'list', locale],
  details: (moduleId: string, collectionId: string, locale: Locale) => [
    CollectionsQueryKeys.PREFIX,
    'details',
    moduleId,
    collectionId,
    locale,
  ],
};

// =================================================================

export const NotificationQueryKeys = {
  PREFIX: 'notification',

  list: (locale: Locale) => [NotificationQueryKeys.PREFIX, 'list', locale],
  details: (notificationId: string, locale: Locale) => [
    NotificationQueryKeys.PREFIX,
    'details',
    notificationId,
    locale,
  ],
};

// =================================================================

export const SearchQueryKeys = {
  PREFIX: 'search',

  list: (query: string) => [SearchQueryKeys.PREFIX, 'search-list', query],
};

// =================================================================

export const MovieCommentQueryKeys = {
  PREFIX: 'movie-comments',

  list: (movieId: string, currentPage: number) => [
    MovieCommentQueryKeys.PREFIX,
    'list',
    movieId,
    currentPage.toString(),
  ],
  replies: (moduleId: string, movieId: string, commentId: string) => [
    MovieCommentQueryKeys.PREFIX,
    'replies',
    moduleId,
    movieId,
    commentId,
  ],
};

// =================================================================

export const OnlineTVQueryKeys = {
  PREFIX: 'online-tv',

  channels: (query: string, locale: Locale) => [
    OnlineTVQueryKeys.PREFIX,
    'channels',
    query,
    locale,
  ],
  category: (locale: Locale) => [OnlineTVQueryKeys.PREFIX, 'category', locale],
};
